import React, { useState, useEffect } from 'react';
import Carousel from 'nuka-carousel';

import './styles.css';
import './quotesAndSponsors.css';

const NukaCarouselQuotes = ({donations, setResponsiveSlideshow}) => {
  const [quotesToShow, setQuotesToShow] = useState(1);

  let config;
  if (donations.length > 1) {
    config = {
      containerClassName: 'ContainerAds',
      nextButtonClassName: 'next u-layoutBtnColor',
      // nextButtonStyle: CSSProperties,
      nextButtonText: '\uf054',
      pagingDotsClassName: 'PageDots',
      // pagingDotsContainerClassName: string,
      // pagingDotsStyle: CSSProperties,
      prevButtonClassName: 'prev u-layoutBtnColor',
      // prevButtonStyle: CSSProperties,
      prevButtonText: '\uf053'
    }
  } else {
    config = {
      containerClassName: 'ContainerAds',
      nextButtonClassName: 'd-none',
      // nextButtonStyle: CSSProperties,
      nextButtonText: '\uf054',
      pagingDotsClassName: 'd-none',
      // pagingDotsContainerClassName: string,
      // pagingDotsStyle: CSSProperties,
      prevButtonClassName: 'd-none',
      // prevButtonStyle: CSSProperties,
      prevButtonText: '\uf053'
    }
  }

///*
// config for setting the amount of slides to show the user at certain breakpoints
  const setShowSlideAmt = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) setQuotesToShow(1);
    if (767 < windowWidth && windowWidth < 992) setQuotesToShow(2);
    if (991 < windowWidth && windowWidth < 1200) setQuotesToShow(3);
    if (1199 < windowWidth) setQuotesToShow(3);
  }
//*/
  useEffect(() => {
    if (setResponsiveSlideshow) {
      console.log('setResponsiveSlideshow', setResponsiveSlideshow);
      // Set amount of slides that are shown on screen
      setShowSlideAmt();
      if(donations.length > 1) {
        // add listener to window to we can check how wide it is and change amount to show
        window.addEventListener("resize", setShowSlideAmt);

        // remove event listener when closed
        return () => window.removeEventListener("resize", setShowSlideAmt);
      }
    }
  }, [])
//*/
  return (
    <Carousel
      cellSpacing={10}
      slidesToShow={quotesToShow}
      defaultControlsConfig={config}
      wrapAround={true}
    >
      {
        donations.map( (donationObj, idx) => {
          return (
            <div key={idx} className="QuoteCard">
              <div className="shadow">
                <div className="Content">
                  <i className="fa-solid fa-quote-left"></i>
                  <p>
                    {donationObj.donator_message}
                  </p>
                  <div className="SponsorInfo">
                    <p>
                      {donationObj.private_donation ?
                        'Anonymous' : `${donationObj.effective_donator_name}`
                      }<br />
                      {donationObj.created_at}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </Carousel>
  )
}

export default NukaCarouselQuotes;
