import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded';

import NukaCarouselQuotes from '../../src/nukaCarousel/NukaCarousel-Quotes.jsx';
import NukaCarouselSponsors from '../../src/nukaCarousel/NukaCarousel-Sponsors.jsx';

// Get quotes element and create NukaCarousel
let quoteContainer = document.getElementById('Sidebar-sponsorSlideshow');
if (quoteContainer) {
  ReactDOM.render(<NukaCarouselQuotes
    donations={preloaded.last_donations_with_messages}
    setResponsiveSlideshow={false}
    />, quoteContainer);
}

// Get sponsor element and create NukaCarousel
let sponsorContainer = document.getElementById('Sidebar-SponsorBySlideshow');
if (sponsorContainer) {
  ReactDOM.render(<NukaCarouselSponsors sponsors={preloaded.email_ads} />, sponsorContainer);
}