import React, { useState, useEffect } from 'react';
import Carousel from 'nuka-carousel';

import './styles.css';
import './quotesAndSponsors.css';

const NukaCarouselSponsors = ({sponsors}) => {
  const [adsToShow, setAdsToShow] = useState(1);

  let config = {
    containerClassName: 'ContainerAds',
    nextButtonClassName: 'next u-layoutBtnColor',
    // nextButtonStyle: CSSProperties,
    nextButtonText: '\uf054',
    pagingDotsClassName: 'd-none',
    // pagingDotsContainerClassName: string,
    // pagingDotsStyle: CSSProperties,
    prevButtonClassName: 'prev u-layoutBtnColor',
    // prevButtonStyle: CSSProperties,
    prevButtonText: '\uf053'
  }

/*
// config for setting the amount of slides to show the user at certain breakpoints
  const setShowSlideAmt = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) setSlidesToShow(1);
    if (767 < windowWidth && windowWidth < 992) setSlidesToShow(2);
    if (991 < windowWidth && windowWidth < 1200) setSlidesToShow(3);
    if (1199 < windowWidth) setSlidesToShow(4);
  }
*/
  useEffect(() => {
    // Set amount of slides that are shown on screen
    // setShowSlideAmt();

    // add listener to window to we can check how wide it is and change amount to show
    // window.addEventListener("resize", setShowSlideAmt);

    // remove event listener when closed
    // return () => window.removeEventListener("resize", setShowSlideAmt);
  }, [])
//*/
  return (
    <Carousel
      cellSpacing={10}
      slidesToShow={adsToShow}
      defaultControlsConfig={config}
      wrapAround={true}
    >
      {
        sponsors.map( (objInfo, idx) => {
          return (
            <div key={idx} className="Sponsor shadow-sm">
              <strong>Sponsor &#35;{idx + 1}</strong>
              <div className="Content">
                <div className="SponsorInfo">
                  <h4>{objInfo.name}</h4>
                  <p>
                    {objInfo.slogan}
                  </p>
                  {
                    objInfo._embedded && objInfo._embedded.picture ? (
                      <img src={objInfo._embedded.picture._links.file.href} alt={objInfo.name} />
                    ): null
                  }
                  <a className="VisitSponsor" target="_blank" rel="nofollow" href={objInfo.url}>
                    Visit Sponsor
                  </a>
                </div>
              </div>
            </div>
          )
        })
      }
    </Carousel>
  )
}

export default NukaCarouselSponsors;
